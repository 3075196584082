<template>
  <div
    id="lookData"
  >
    <CSBreadcrumb />
    <div class="page-container page-header">
      <input
        type="text"
        value=""
        class="native-input"
        placeholder="搜索资料名称"
        v-model="fileName"
      >
      <button
        type="button"
        class="btn btn-primary checkBtn"
        @click="queryFile()"
      >
        查询
      </button>
    </div>
    <div class="result-panel">
      <div class="result-panel-header text-right">
        <span
          style="font-size: 14px; color: #999;"
        >
            <svg
                class="icon"
                aria-hidden="true"
            >
                <use xlink:href="#icon-menua-zu92"></use>
            </svg>
            为保证上传的视频可同时在安卓/苹果手机正常观看，请确保上传的视频编码为“h264”，可使用“格式工厂”查看编码或转换编码。
        </span>
        <button
          type="button"
          class="btn btn-primary"
          @click="triggerChooseFile"
        >
          <svg
            class="icon"
            aria-hidden="true"
          >
            <use xlink:href="#icon-menujiahao"></use>
          </svg>
          上传资料
          <input
            type="file"
            name="file"
            id="uploadStaffFile"
            hidden
            @change="chooseStaffFile($event)"
          >
        </button>
      </div>
        <CSTable :thead-top="60">
            <template v-slot:thead>
                <tr>
                    <th>上传时间</th>
                    <th>资料名称</th>
                    <th>创建人</th>
                    <th>操作</th>
                </tr>
            </template>
            <template v-slot:tbody>
                <tr
                    v-for="file in fileList"
                    :key="file.id"
                    style="text-align: center;"
                >
                    <td class="date-td">{{ file.createTime }}</td>
                    <td>
              <span
                  class="allow-click"
                  v-if="judgeVideoFile(file.fileName) == 'file'"
              >
                  <a style="text-decoration: none; color: #1dafff; outline:none;" :href="file.url" target='_blank'>{{ file.fileName }}</a>
              </span>
                        <span
                            class="allow-click"
                            v-else
                            @click.stop="phoneVisible(file.url, file.fileName)"
                        >
                {{ file.fileName}}
              </span>
                    </td>
                    <td >
                        {{ file.createBy + "【" + file.jobTitle + "】" }}
                    </td>
                    <td >
                        <div
                            class="btn-group"
                        >
                            <button
                                type="button"
                                class="btn btn-primary dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                操作
                            </button>
                            <ul
                                class="dropdown-menu"
                                style="min-width: 85px; width: 85px;"
                            >
                                <li
                                    @click="editFileNameBtn(file.id, file.fileName)"
                                >
                                    <a>重命名</a>
                                </li>
                                <li
                                    @click="deleteFileBtn(file.id)"
                                >
                                    <a>删除</a>
                                </li>
                            </ul>
                        </div>
                    </td>
                </tr>
            </template>
        </CSTable>
      <!-- 分页 -->
      <Pagination
          name="pagination"
          componentName="Pagination"
      ></Pagination>
    </div>
    <CSDialog
      :dialogVisible="eidtFileNameVisible"
      dialogWidth="596px"
      dialogTitle="重命名"
      @onClose="eidtFileNameVisible = false"
      @onConfirm="eidtFileNameQuery"
    >
      <div
        slot="dialog-content"
        style="padding: 27px 30px 30px 30px"
      >
        <div
          class="notice_title"
        >
          资料名称
        </div>
        <input
          type="text"
          placeholder="限30个字"
          maxlength="30"
          v-model="editFileNameQuery.fileName"
          class="notice_input"
        >
      </div>
    </CSDialog>
    <div
      class="preview preview-layer"
      @click.stop
      @click="RealPhonePreviewVisible = false"
      v-if="RealPhonePreviewVisible"
    >
      <RealPhonePreview>
        <template slot="preview">
          <div
            style="width: 100%; height: 100%; background-color: #F0F0F0; "
          >
            <div
              class="nav"
            >
              <span
                class="left"
              > &lt; </span>
              <span
                class="center"
              >
                {{ videoName }}
              </span>
            </div>
            <video :src="videoUrl" controls height="250px" ></video>
            <div
              class="back"
            >
              返回
            </div>
          </div>
        </template>
      </RealPhonePreview>
    </div>
    <div class="layer" v-if="speedProgressVisible">
      <div style="position: absolute; top: 50%; left: 50%; font-size: 18px; color: #fff; transform: translate(-50%, -50%);">
        <div class="rotate-out-center">
        </div>
        <div>
          已上传{{ speedProgress + "%" }}，请耐心等待...
        </div>
        <div style="text-align: center;">
          <button
            type="button"
            class="btn btn-primary"
            style="margin-top: 20px;"
            @click="cancelUpData"
          >
            取消上传
          </button>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import CSDialog from "@/components/common/CSDialog";
import RealPhonePreview from "@/components/RealPhonePreview";
import Pagination from "@/components/Pagination";
import CSBreadcrumb from "@/components/common/CSBreadcrumb";

import {
  commonImgOssDomain,
  selectFileByName,
  addFile,
  deleteFile,
  listFiles,
  uploadFileUrl,
  editFileName,
  ossKey,
} from '@/requestUrl'
import CSTable from "@/components/common/CSTable";

  export default {
    name: "LookData",
    props: {
      folderId: Number,
      folderCreateId: Number,
    },
    components: {
        CSTable,
      CSDialog,
      RealPhonePreview,
      Pagination,
      CSBreadcrumb,
    },
    data() {
      return {
        // 用于查询的文件名
        fileName: "",
        // 文件列表
        fileList: [],
        // 文件数量
        fileListLength: "",
        // 上传文件params
        addFileParams: {
          folderId:"",                              //文件夹id
          repositoryFileNameAndUrlRequests:[{       //文件名和url 必填
            fileName:"",                            //文件名  必填
            url:""                                  //文件url  必填
          }],
          regionCode:"",                            //园区编码
        },
        // 查询文件请求体
        queryFileInfo: {
          folderId: this.folderId ,          //文件夹id 必填
          pageNo: 1,                         // 页码，选填
          pageSize: 10,                      // 每页条目数量，选填
        },
        // 预览效果显隐
        RealPhonePreviewVisible: false,
        // 重命名对话框显隐
        eidtFileNameVisible: false,
        // 进度显隐
        speedProgressVisible: false,
        // 重命名请求信息
        editFileNameQuery: {
          fileName: "",
          id: "",
        },
        // 视频播放地址
        videoUrl: "",
        // 视频名称
        videoName: "",
        // 用户信息
        userInfo: this.$vc.getCurrentStaffInfo(),
        // 进度
        speedProgress: 0,
        // oss实例
        ossClient: {},
      }
    },
    created() {
      // 初始获取文件列表
      this.getFileList();
    },
    mounted() {
      this.$vc.on(this.$route.path, "pagination", "page_event", (page) => {
        this.queryFileInfo.pageNo = page;
        this.getFileList();
      })
    },
    methods: {
      //  调起选择文件
      triggerChooseFile() {
        if (this.folderCreateId != this.userInfo.id) {
          this.$vc.toast("资料夹创建人可上传");
          return;
        }
        $("#uploadStaffFile").trigger("click");
      },
      // 校验文件类型
      verifyFileType(name) {
        let Suffix = /\.(mp4|mov|m4v|3gp|avi|m3u8|webm|doc|docx|xls|xlsx|ppt|pptx|pdf|jpg|png|tif|gif)$/;
        return Suffix.test(name);
      },
      // 取消上传
      cancelUpData() {
        this.ossClient.terminationUpload();
        this.speedProgressVisible = false;
        this.speedProgress = 0;
      },
      // 选择文件后处理
      chooseStaffFile(event) {
        if (this.fileListLength >= 30) {
          this.$vc.toast("文件数量不能多余30个");
          return;
        }
        let files = event.target.files;
        let file = files[0];
        if (!this.verifyFileType(file.name)) {
          this.$vc.toast("可选后缀名为mp4,mov,m4v,3gp,avi,m3u8,webm,doc,docx,xls,xlsx,ppt,pptx,pdf,jpg,png,tif,gif 格式的文档");
          return false;
        }
        this.speedProgressVisible = true;
        this.$fly
            .putFile(file, (ossObject) => {
              this.speedProgress = ossObject.progress,
              this.ossClient = ossObject.ossClient
            })
            .then(res => {
              this.speedProgressVisible = false;
              this.addFileParams.repositoryFileNameAndUrlRequests = [{
                fileName: res.fileName,
                url: res.url
              }]
              this.uploadInformation();
            },)
        event.target.value = "";
      },
      // 上传文件
      uploadInformation() {
        this.$fly
            .post(addFile, {
              ...this.addFileParams,
              folderId: this.folderId,
              regionCode: this.$vc.getCurrentStaffInfo().regionCode,
            })
            .then(res => {
              if (res.code !== 0) {
                this.$vc.toast("上传文件失败");
                return;
              }
              this.$vc.toast("上传文件成功");
              // 重新获取文件列表
              this.getFileList();
            })
      },
      // 查询所有文件
      getFileList() {
        this.$fly
            .post( listFiles, this.queryFileInfo )
            .then(res => {
              if (res.code !== 0) {
                return;
              }
              this.fileListLength = res.data.length;
              this.fileList = res.data;
            })
      },
      // 根据文件名查询文件
      queryFile(pageNo = 1,pageSize = 10) {
        this.queryFileInfo.pageNo = 1;
        this.$fly
            .post(selectFileByName, {
              fileName: this.fileName,
              folderId: this.folderId
            })
            .then(res => {
              if (res.code !== 0) {
                return;
              }

              this.fileList = res.data.datas;
              // TODO:页数,没有文件 之后还需要添加数据实际检验
            })
      },
      // 删除文件按钮
      deleteFileBtn(fileId) {
        if (this.folderCreateId != this.userInfo.id) {
          this.$vc.toast("资料夹创建人可删除");
          return;
        }
        this.$CSDialog.confirm({
          title: "提示",
          message: "确认删除资料吗?",
          onConfirm: () => {
            this.$fly
                .post(deleteFile, {
                  fileId,
                })
                .then((res) => {
                  if (res.code !== 0) {
                    return;
                  }
                  this.$vc.toast("删除成功");
                  this.$CSDialog.instance.closeDialog();
                  this.getFileList();
                })
          }
        })
      },
      // 重命名按钮
      editFileNameBtn(fileId, fileName) {
        if (this.folderCreateId != this.userInfo.id) {
          this.$vc.toast("资料夹创建人可重命名");
          return;
        }
        this.eidtFileNameVisible = true;
        this.editFileNameQuery.fileName = fileName;
        this.editFileNameQuery.id = fileId;
      },
      // 修改文件名
      eidtFileNameQuery() {
        this.$fly
            .post(editFileName, this.editFileNameQuery)
            .then(res => {
              if (res.code !== 0) {
                this.$vc.toast("修改文件名失败");
                return;
              }
              this.$vc.toast("修改文件名成功");
              this.eidtFileNameVisible = false;
              this.getFileList();
            })
      },
      // 判断是视频还是文件
      judgeVideoFile(fileName) {
        let reg = /\.(mp4|mov|m4v|3gp|avi|m3u8|webm)$/;
        return reg.test(fileName) ? "video" : "file"
      },
      phoneVisible(fileUrl) {
        window.open(fileUrl);
        /*this.RealPhonePreviewVisible = true;
        this.videoUrl =  fileUrl;
        this.videoName = fileName;*/
      }
    }
  }
</script>

<style lang="stylus" scoped>
.nav-bar
  width 100%
  background #FFFFFF
  box-shadow 0px 5px 10px 0px rgba(0, 0, 0, 0.1)
  font-size 24px
  color var(--pc-theme-color)
  padding 18px 30px
  box-sizing border-box
  div
    line-height 24px
    display inline-block
.page-container
  background #fff
  margin 20px 0px
  box-shadow -10px 0 20px 0 rgba(0, 0, 0, 0.1)
.page-header
  padding 15px 30px 15px 30px
.native-input
  width 180px
  height 30px
  border-radius 3px
  border 1px solid #979797
  padding-left 10px
  font-size 14px
  vertical-align middle
.checkBtn
  margin-left 20px
  width 50px
  height 30px;
  font-size 14px
  padding 0
  vertical-align middle
.notice_title
  display inline-block
  font-size 22px
  color #000
  text-align right
  margin-right 40px
  vertical-align middle
.notice_input
  width: 400px;
  vertical-align middle;
  height: 40px
  font-size 24px
.preview
  position fixed
  top 50%
  left 50%
  transform translate(-50%, -50%)
.nav
  height: 34px
  background-color #fff
  line-height 34px
  font-size 15px
  position relative
  .left
    margin-left 10px
  .center
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
.back
  width 100%;
  height 40px;
  background-color #FF5A5A;
  position absolute;
  bottom 0
  text-align center
  font-size 19px
  color #FFFFFF;
  line-height 40px
.rotate-out-center
  position relative
  top 50%
  left 50%
  transform translate(-50%, -50%)
  border-top 5px solid #fff
  border-radius 50%
  width 70px
  height 70px
  animation rotate-out-center 0.8s cubic-bezier(0.550, 0.085, 0.680, 0.530) both infinite
@keyframes rotate-out-center
  0%
    transform translate(-50%, -50%) rotate(0)
  100%
    transform translate(-50%, -50%) rotate(360deg)
</style>
